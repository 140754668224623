@import "@/styles/theme/main.scss";
.wrapper {
  position: relative;

  &__container {
    all: unset;
    box-sizing: border-box;
    aspect-ratio: 1;
    width: 100%;
    height: $box-height;
    padding: 4px;
    border-radius: $border-radius;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s both;
    overflow: hidden;

    &__image {
      width: 100%;
      height: 100%;
      transition: transform 0.3s;
      z-index: 1;
      object-fit: cover;
    }

    @media (max-width: 550px) {
      height: 100%;
      max-height: 50px;
    }

    &__skeleton {
      @include skeleton;
      width: 100%;
      border-radius: $border-radius;
      height: $box-height;

      &--isSmall {
        height: 32px;
      }
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      display: flex;
      pointer-events: none;
      opacity: 0.3;
      z-index: 0;
      background-color: var(--data-color);
      transition: all 0.3s ease-in-out;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 0.3s;
    }

    &:hover {
      &::after {
        opacity: 0.6;
      }
    }

    &--isSmall {
      height: 32px;
    }

    &--isDisabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }
}
